import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Судебное взыскание долгов | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Одни пытаются осуществить вернуть деньги самостоятельно, однако, не имея профессиональных навыков и опыта, как правило сделать этого не могут." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/sudebnoe-vzyskanie-dolgov'
				name="Судебное взыскание долгов | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Одни пытаются осуществить вернуть деньги самостоятельно, однако, не имея профессиональных навыков и опыта, как правило сделать этого не могут. "
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Судебное взыскание долгов" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Судебное взыскание долгов"}
			
				html={

				`

				<p class="TextText">
				В настоящее время практически каждая организация может столкнуться с такой проблемой, как взыскание долга. На фоне всеобщего экономического кризиса вопрос взыскания долгов с должника является весьма злободневной темой. Часто юридические лица сталкиваются с необходимостью возврата долга в следствии неисполнения партнером своих обязательств по договору.
				</p>

				<p class="TextText">
				Одни пытаются осуществить вернуть деньги самостоятельно, однако, не имея профессиональных навыков и опыта, как правило сделать этого не могут. Главным, а порой и решающим фактором по возврату долга является своевременное принятие определенных мер, направленных на сохранение имущества должника. Но не зная этих тонкостей, кредиторы упускают время, в течение которого должнику либо удается скрыть, имеющееся у него имущество, либо начать процедуру банкротства, что в любом случае приведет к невозможности возврата денежных средств кредитору.
				</p>

				<p class="TextText">
				В связи с чем, только своевременное обращение юридического лица за помощью по возврату долга к квалифицированным юристам, может исключить столь печальное развитие событий.
				</p>

				<p class="TextText">
				Процесс взыскания долга может состоять из нескольких стадий, каждая из которых по своему специфична. Юридическая помощь на первой стадии возможна без вмешательства суда, т.е. в претензионном порядке. Юрист, благодаря своим профессиональным навыкам в большинстве случаев может убедить должника вернуть долг добровольно.
				</p>

				<p class="TextText">
				Если же должник отказался от добровольного возврата долга, либо вообще заявил, что не считает себя должником, то наступает другая стадия — судебное взыскание долга. На этой стадии необходимо инициировать судебный процесс. Однако тут необходимо не только грамотно подать соответствующее заявление в суд и принять меры обеспечительного характера, но и правильно вести судебное дело. Взыскание долга, быстрота и полнота удовлетворения требований кредитора здесь в значительной мере зависит от опыта и профессионализма участника судебного процесса. Данная стадия заканчивается вынесением судебного решения.
				</p>

				<p class="TextText">
				Далее в дело должны вступить судебные приставы, наделенные правом исполнять решение суда (вести исполнительное производство), которые зачастую, не имеют особого желания воплощать в жизнь решение суда. Поэтому суть действия на этой стадии для юридического лица сводится к тому, чтобы сделать все возможное для исполнения судебным приставом своих должностных обязанностей, которые они в силу большой загруженности либо низкой квалификации и т.д., не осуществляют должным образом. Иными словами кредитору предстоит выполнить часть работы за судебных приставов, которые делают свою работу кое-как, либо вообще ею не занимаются. Бездействие судебных приставов зачастую приводит к невозможности взыскания долга с должника из-за его банкротства либо скрытия его имущества (продажа, перевод на другое лицо и т.д.)
				</p>

				<p class="TextText">
				В деле по возврату долгов нет шаблонных решений, каждая ситуация индивидуальна и требует особого подхода.
				</p>


				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
